// Theme Presets

const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#343434",
  dark: "#000000",
  secondaryText: "#7F8DAA",
  backgroundTabs: "#f5f5f5",
  backgroundDetail: "#fafafa",
  accentColor: "#3780FF",
  accentBright: "#5f99ff",
  projectCard: "#DCE4F2",
  skinColor: "#ae7461",
  skinColor2: "#6f4439",
  imageDark: "#dce4f2",
  imageClothes: "#3780FF",
  avatarMisc: "#e9ecf2",
  avatarMiscKey: "#212121",
  degreeCard: "#38b137"
};

const darkTheme = {
  name: "dark",
  body: "#1D1D1D",
  text: "#FFFFFF",
  dark: "#000000",
  secondaryText: "#8D8D8D",
  backgroundTabs: "#292A2D",
  backgroundDetail: "#e9ecf2",
  accentColor: "#3780FF",
  accentBright: "#5f99ff",
  projectCard: "#292A2D",
  skinColor: "#ae7461",
  skinColor2: "#6f4439",
  imageDark: "#292A2D",
  imageClothes: "#292A2D",
  avatarMisc: "#212121",
  avatarMiscKey: "#e9ecf2",
  degreeCard: "#292A2D"
};

export const themes = { light: lightTheme, dark: darkTheme };
