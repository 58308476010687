import React from "react";
import "./FeelingProud.css";

function FeelingProud(props) {
  const theme = props.theme;
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <g id="freepik-backgorund">
          <path
            d="M30.71,160.76a17.91,17.91,0,0,1,4.41-1.91l.9-4.37,7.38-.13,1.06,4.33a18.16,18.16,0,0,1,4.47,1.76L52.66,158l5.3,5.12-2.31,3.81a18.33,18.33,0,0,1,1.92,4.41l4.36.91.13,7.37-4.33,1.06A18.16,18.16,0,0,1,56,185.15l2.44,3.73-5.12,5.3-3.81-2.31a17.94,17.94,0,0,1-4.41,1.92l-.9,4.36-7.38.13L35.73,194a17.78,17.78,0,0,1-4.47-1.76l-3.73,2.45-5.3-5.13,2.31-3.81a18.26,18.26,0,0,1-1.92-4.4l-4.36-.91L18.13,173,22.46,172a18.23,18.23,0,0,1,1.76-4.48l-2.44-3.72,5.12-5.31,3.81,2.31Zm3.93,9.82a7.91,7.91,0,1,0,11.18-.19A7.91,7.91,0,0,0,34.64,170.58Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M286.14,37.2a15.34,15.34,0,0,1,3.75-1.63l.78-3.72,6.27-.11.9,3.69a15.31,15.31,0,0,1,3.81,1.49l3.17-2.08,4.52,4.36-2,3.25A15.18,15.18,0,0,1,309,46.2l3.72.77.11,6.28-3.69.9a15.11,15.11,0,0,1-1.5,3.81l2.08,3.17-4.36,4.51-3.24-2a15.56,15.56,0,0,1-3.75,1.63L297.6,69l-6.28.11-.9-3.68a15.74,15.74,0,0,1-3.81-1.5L283.44,66l-4.51-4.36,2-3.24a15.25,15.25,0,0,1-1.63-3.76l-3.71-.77-.11-6.27,3.68-.91a15.42,15.42,0,0,1,1.5-3.8l-2.08-3.18,4.36-4.51,3.24,2Zm3.35,8.35a6.73,6.73,0,1,0,9.52-.16A6.73,6.73,0,0,0,289.49,45.55Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M74,41.46a25.13,25.13,0,0,1,6.1-2.65l1.25-6,10.19-.18,1.47,6A24.6,24.6,0,0,1,99.19,41l5.15-3.38,7.33,7.08L108.48,50a25.63,25.63,0,0,1,2.65,6.09l6,1.26.18,10.19-6,1.47a24.53,24.53,0,0,1-2.43,6.18l3.38,5.15-7.08,7.34L100,84.47a24.75,24.75,0,0,1-6.1,2.65l-1.25,6-10.19.17-1.47-6a25.15,25.15,0,0,1-6.18-2.43L69.6,88.29l-7.33-7.08L65.46,76a25.1,25.1,0,0,1-2.64-6.1l-6-1.25L56.6,58.4l6-1.46A25,25,0,0,1,65,50.75L61.65,45.6l7.08-7.33L74,41.46ZM79.43,55a10.93,10.93,0,1,0,15.46-.27A10.92,10.92,0,0,0,79.43,55Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M238.38,216a18.07,18.07,0,0,1,4.41-1.91l.91-4.37,7.37-.13,1.06,4.33a18,18,0,0,1,4.47,1.76l3.73-2.44,5.3,5.12-2.3,3.81a17.91,17.91,0,0,1,1.91,4.41l4.36.91.13,7.37-4.33,1.06a18.13,18.13,0,0,1-1.75,4.47l2.44,3.73L261,249.4l-3.81-2.31a18.1,18.1,0,0,1-4.41,1.92l-.91,4.36-7.37.13-1.06-4.33a17.63,17.63,0,0,1-4.47-1.76l-3.73,2.45-5.31-5.13,2.31-3.8a18.07,18.07,0,0,1-1.91-4.41l-4.36-.91-.13-7.37,4.32-1.06a18.63,18.63,0,0,1,1.76-4.48L229.45,219l5.12-5.31,3.81,2.31Zm3.93,9.82a7.91,7.91,0,1,0,11.19-.19A7.92,7.92,0,0,0,242.31,225.8Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M259.15,347.92a18.33,18.33,0,0,1,4.41-1.92l.9-4.36,7.38-.13,1.06,4.33a17.78,17.78,0,0,1,4.47,1.76l3.73-2.44,5.3,5.12-2.31,3.81a18.26,18.26,0,0,1,1.92,4.4l4.36.91.13,7.37-4.33,1.07a18.16,18.16,0,0,1-1.76,4.47l2.44,3.72-5.12,5.31L277.92,379a17.91,17.91,0,0,1-4.41,1.91l-.9,4.37-7.38.13-1.06-4.33a18.16,18.16,0,0,1-4.47-1.76L256,381.79l-5.31-5.12,2.31-3.81a18.33,18.33,0,0,1-1.92-4.41l-4.36-.91-.13-7.37,4.33-1.06a18.16,18.16,0,0,1,1.76-4.47l-2.44-3.73,5.12-5.3,3.81,2.31Zm3.93,9.82a7.91,7.91,0,1,0,11.18-.2A7.9,7.9,0,0,0,263.08,357.74Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M82.16,265.36a12,12,0,0,1,3-1.29l.61-2.95,5-.08.71,2.91a12.57,12.57,0,0,1,3,1.19L97,263.49l3.58,3.46L99,269.52a12,12,0,0,1,1.29,3l2.94.61.09,5-2.92.72a12.1,12.1,0,0,1-1.19,3l1.65,2.52-3.45,3.57-2.57-1.56a12.41,12.41,0,0,1-3,1.3l-.62,2.94-5,.09-.71-2.92a12.57,12.57,0,0,1-3-1.19L80,288.2l-3.58-3.46L78,282.18a11.9,11.9,0,0,1-1.29-3l-2.94-.61-.09-5,2.92-.72a12.47,12.47,0,0,1,1.18-3l-1.64-2.51,3.45-3.58,2.57,1.56ZM84.81,272a5.33,5.33,0,1,0,7.54-.13A5.33,5.33,0,0,0,84.81,272Z"
            fill={theme.backgroundTabs}
          ></path>
          <path
            d="M89.54,303.66A22.2,22.2,0,0,1,95,301.31l1.12-5.37,9.06-.16,1.3,5.32a22.11,22.11,0,0,1,5.5,2.17l4.58-3,6.52,6.29-2.84,4.68a22,22,0,0,1,2.35,5.42l5.37,1.12.16,9.06-5.32,1.3a22.11,22.11,0,0,1-2.17,5.5l3,4.58-6.29,6.52-4.68-2.84a22.4,22.4,0,0,1-5.42,2.36l-1.12,5.36-9.06.16-1.3-5.32a22.11,22.11,0,0,1-5.5-2.17l-4.58,3L79.12,339,82,334.32a22.23,22.23,0,0,1-2.36-5.42l-5.36-1.12-.16-9.06,5.32-1.3a22.11,22.11,0,0,1,2.17-5.5l-3-4.58,6.3-6.52,4.68,2.84Zm4.83,12.07a9.72,9.72,0,1,0,13.75-.24A9.73,9.73,0,0,0,94.37,315.73Z"
            fill={theme.backgroundTabs}
          ></path>
          <rect
            x="341.15"
            y="46.08"
            width="137.54"
            height="231.75"
            fill={theme.body}
          ></rect>
          <rect
            x="344.86"
            y="50.44"
            width="129.66"
            height="223.58"
            fill={theme.backgroundTabs}
          ></rect>
          <rect
            x="409.69"
            y="62.38"
            width="51.7"
            height="80.22"
            fill={theme.body}
          ></rect>
          <path
            d="M438.29,69.47a1.93,1.93,0,1,1-1.93-1.93A1.93,1.93,0,0,1,438.29,69.47Z"
            fill={"#e9ecf2"}
          ></path>
          <rect
            x="357.58"
            y="164.93"
            width="51.7"
            height="80.22"
            fill={theme.body}
          ></rect>
          <path
            d="M386.18,172a1.93,1.93,0,1,1-1.93-1.93A1.93,1.93,0,0,1,386.18,172Z"
            fill={"#e9ecf2"}
          ></path>
          <rect
            x="356.88"
            y="62.38"
            width="44.15"
            height="62.65"
            fill={theme.body}
          ></rect>
          <path
            d="M380.56,68.07a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,380.56,68.07Z"
            fill={"#e9ecf2"}
          ></path>
          <rect
            x="417.19"
            y="168.62"
            width="47.52"
            height="26.33"
            transform="translate(-17.6 50.58) rotate(-6.44)"
            fill={theme.body}
          ></rect>
          <circle cx="440.03" cy="173.66" r="1.64" fill={"#e9ecf2"}></circle>
          <path
            d="M402.38,183.23c0,.22-8.11.39-18.13.39s-18.13-.17-18.13-.39,8.12-.38,18.13-.38S402.38,183,402.38,183.23Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,189c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,188.76,402.38,189Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,194.71c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,194.5,402.38,194.71Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,200.45c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,200.24,402.38,200.45Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,206.19c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,206,402.38,206.19Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,211.93c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,211.72,402.38,211.93Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M402.38,217.67c0,.21-8.11.39-18.13.39s-18.13-.18-18.13-.39,8.12-.38,18.13-.38S402.38,217.46,402.38,217.67Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M458.66,177.08c0,.21-8,1.52-17.9,2.92s-18,2.36-18,2.15,8-1.51,17.9-2.91S458.63,176.87,458.66,177.08Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M459.38,180.67c0,.21-8,1.52-17.9,2.92s-18,2.36-18,2.15,8-1.51,17.9-2.91S459.35,180.46,459.38,180.67Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M460,184c0,.21-8,1.52-17.9,2.91s-18,2.37-18,2.16,8-1.52,17.9-2.92S460,183.78,460,184Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,79.16c0,.22-9.17.39-20.48.39s-20.49-.17-20.49-.39,9.17-.38,20.49-.38S456.84,79,456.84,79.16Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,82.58c0,.21-9.17.38-20.48.38s-20.49-.17-20.49-.38,9.17-.39,20.49-.39S456.84,82.36,456.84,82.58Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,86c0,.21-9.17.39-20.48.39s-20.49-.18-20.49-.39,9.17-.39,20.49-.39S456.84,85.78,456.84,86Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,89.4c0,.22-9.17.39-20.48.39s-20.49-.17-20.49-.39S425,89,436.36,89,456.84,89.19,456.84,89.4Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,92.82c0,.21-9.17.38-20.48.38s-20.49-.17-20.49-.38,9.17-.39,20.49-.39S456.84,92.6,456.84,92.82Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,96.23c0,.21-9.17.38-20.48.38s-20.49-.17-20.49-.38,9.17-.39,20.49-.39S456.84,96,456.84,96.23Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,99.64c0,.21-9.17.39-20.48.39s-20.49-.18-20.49-.39,9.17-.38,20.49-.38S456.84,99.43,456.84,99.64Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M456.84,109.77c0,.21-9.17.38-20.48.38s-20.49-.17-20.49-.38,9.17-.39,20.49-.39S456.84,109.56,456.84,109.77Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M457.13,113.37c0,.22-9.17.39-20.48.39s-20.49-.17-20.49-.39,9.17-.38,20.49-.38S457.13,113.16,457.13,113.37Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M457.42,117c0,.21-9.18.38-20.49.38s-20.48-.17-20.48-.38,9.17-.39,20.48-.39S457.42,116.77,457.42,117Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M457.7,120.58c0,.22-9.17.39-20.48.39s-20.48-.17-20.48-.39,9.17-.38,20.48-.38S457.7,120.37,457.7,120.58Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M458,124.19c0,.21-9.17.38-20.48.38S417,124.4,417,124.19s9.16-.39,20.48-.39S458,124,458,124.19Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M458.28,127.79c0,.22-9.17.39-20.48.39s-20.49-.17-20.49-.39,9.17-.38,20.49-.38S458.28,127.58,458.28,127.79Z"
            fill={"#e9ecf2"}
          ></path>
          <path
            d="M170.93,91.76H141.3a3.71,3.71,0,0,1-3.71-3.7V47a3.71,3.71,0,0,1,3.71-3.7h18.79l14.55,15V88.06A3.71,3.71,0,0,1,170.93,91.76Z"
            fill={"#ffcc52"}
          ></path>
          <path
            d="M137.59,77.07h37.05a0,0,0,0,1,0,0V87.23a4.53,4.53,0,0,1-4.53,4.53h-28a4.53,4.53,0,0,1-4.53-4.53V77.07A0,0,0,0,1,137.59,77.07Z"
            fill={"#fcbd05"}
          ></path>
          <path
            d="M150.72,86.55l.93-1.12a1.68,1.68,0,0,0,1.35.8c.67,0,1-.41,1-1.21V81.57h-2.54V80.23h4.21v4.7a2.34,2.34,0,0,1-2.61,2.67A2.93,2.93,0,0,1,150.72,86.55Z"
            fill={"#fafafa"}
          ></path>
          <path
            d="M156.69,87l.54-1.16a3.85,3.85,0,0,0,1.94.54c.75,0,1-.2,1-.51,0-.91-3.37,0-3.37-2.2,0-1.06.95-1.81,2.58-1.81a4.54,4.54,0,0,1,2.14.49L161,83.46a3.21,3.21,0,0,0-1.61-.41c-.73,0-1,.22-1,.51,0,1,3.37,0,3.37,2.23,0,1-1,1.77-2.63,1.77A4.84,4.84,0,0,1,156.69,87Z"
            fill={"#fafafa"}
          ></path>
          <path
            d="M145.44,69.35,146.6,68a3.49,3.49,0,0,0,2.92,1.66c1.59,0,2.41-1,2.41-2.84V58.94h-5.07V57.19h7v9.52c0,3.12-1.52,4.67-4.39,4.67A4.89,4.89,0,0,1,145.44,69.35Z"
            fill={"#fafafa"}
          ></path>
          <path
            d="M156.38,70.09l.8-1.52a6.81,6.81,0,0,0,3.72,1.1c1.79,0,2.53-.54,2.53-1.44,0-2.38-6.71-.32-6.71-4.55,0-1.9,1.7-3.18,4.43-3.18a7.71,7.71,0,0,1,3.86,1L164.19,63a5.54,5.54,0,0,0-3.06-.84c-1.69,0-2.51.62-2.51,1.46,0,2.51,6.73.46,6.73,4.59,0,1.92-1.76,3.15-4.59,3.15A7.65,7.65,0,0,1,156.38,70.09Z"
            fill={"#fafafa"}
          ></path>
          <path
            d="M160.09,43.33V54.87a3.45,3.45,0,0,0,3.45,3.45h11.1Z"
            fill={"#fcbd05"}
          ></path>
          <path
            d="M160.24,55.88a3.84,3.84,0,0,0,3,3.72c.11,0,.24.06.36.09,1.45.26,11,2,11,1.73v-3.1h-11.1S160.77,58.23,160.24,55.88Z"
            fill={"#fcbd05"}
          ></path>
        </g>
        <g id="freepik-files">
          <path
            d="M248,115.07H197.72a6.29,6.29,0,0,1-6.29-6.29V39.22a6.28,6.28,0,0,1,6.29-6.28h31.87l24.67,25.42v50.42A6.29,6.29,0,0,1,248,115.07Z"
            fill={"#38b137"}
          ></path>
          <text
            style={{
              fill: "#fff",
              fontFamily: "Arial Rounded MT",
              fontSize: "21.9px",
              whitespace: "pre",
            }}
            transform="matrix(0.744914, 0, 0, 0.646152, 53.017201, 37.541779)"
            x="200"
            y="109.206"
          >
            JAVA
          </text>
          <g transform="matrix(1.365599, 0, 0, 1.281164, -247.385425, 39.784924)">
            <path
              d="M 336.612 26.682 C 336.612 26.682 335.165 27.311 337.644 27.524 C 340.647 27.78 342.182 27.743 345.491 27.275 C 345.491 27.275 346.362 27.683 347.578 28.035 C 340.158 30.41 330.785 27.898 336.612 26.682 Z M 335.705 23.583 C 335.705 23.583 334.081 24.48 336.562 24.672 C 339.771 24.92 342.304 24.94 346.689 24.309 C 346.689 24.309 347.295 24.769 348.248 25.019 C 339.278 26.979 329.288 25.174 335.705 23.583 Z"
              style={{
                transformBox: "fill-box",
                transformOrigin: "58.4113% -63.3856%",
                fill: "#fafafa",
              }}
            ></path>
            <path
              d="M 343.348 18.324 C 345.177 19.897 342.869 21.312 342.869 21.312 C 342.869 21.312 347.511 19.522 345.379 17.28 C 343.388 15.19 341.861 14.152 350.127 10.571 C 350.127 10.571 337.152 12.991 343.348 18.324 Z"
              style={{
                transformBox: "fill-box",
                transformOrigin: "6.05513% 89.0583%",
                fill: "#fafafa",
              }}
            ></path>
            <path
              d="M 353.162 28.974 C 353.162 28.974 354.233 29.634 351.981 30.145 C 347.7 31.113 334.157 31.406 330.395 30.183 C 329.044 29.744 331.579 29.134 332.377 29.005 C 333.209 28.871 333.683 28.896 333.683 28.896 C 332.18 28.105 323.964 30.449 329.511 31.122 C 344.637 32.954 357.085 30.297 353.162 28.974 Z M 337.309 20.371 C 337.309 20.371 330.421 21.593 334.87 22.038 C 336.749 22.225 340.492 22.182 343.982 21.963 C 346.833 21.785 349.694 21.402 349.694 21.402 C 349.694 21.402 348.69 21.724 347.962 22.095 C 340.966 23.47 327.454 22.829 331.345 21.424 C 334.634 20.236 337.309 20.371 337.309 20.371 Z M 349.666 25.53 C 356.777 22.77 353.489 20.118 351.193 20.475 C 350.632 20.563 350.38 20.639 350.38 20.639 C 350.38 20.639 350.59 20.394 350.988 20.289 C 355.528 19.097 359.019 23.805 349.524 25.669 C 349.524 25.669 349.633 25.596 349.666 25.53 Z"
              style={{
                transformBox: "fill-box",
                transformOrigin: "52.1662% 0.33127%",
                fill: "#fafafa",
              }}
            ></path>
            <path
              d="M 345.379 4.788 C 345.379 4.788 349.316 7.731 341.644 12.255 C 335.491 15.885 340.241 17.954 341.642 20.32 C 338.049 17.899 335.414 15.768 337.182 13.785 C 339.777 10.874 346.968 9.462 345.379 4.788 Z"
              style={{
                transformBox: "fill-box",
                transformOrigin: "56.9624% 98.8202%",
                fill: "#fafafa",
              }}
            ></path>
            <path
              d="M 338.008 33.01 C 344.833 33.336 355.314 32.829 355.563 30.416 C 355.563 30.416 355.086 31.331 349.922 32.056 C 344.097 32.875 336.911 32.78 332.65 32.255 C 332.65 32.255 333.523 32.794 338.008 33.01 Z"
              style={{
                transformBox: "fill-box",
                transformOrigin: "41.6083% -383.144%",
                fill: "#fafafa",
              }}
            ></path>
          </g>
          <g style={{ opacity: 0.2 }}>
            <path d="M192.67,90.15H253a1.24,1.24,0,0,1,1.24,1.24v17.2a6.48,6.48,0,0,1-6.48,6.48H197.91a6.48,6.48,0,0,1-6.48-6.48V91.39A1.24,1.24,0,0,1,192.67,90.15Z"></path>
          </g>
          <g style={{ opacity: 0.2 }}>
            <path
              d="M229.59,32.94V52.51a5.84,5.84,0,0,0,5.84,5.85h18.83Z"
              fill={theme.body}
            ></path>
          </g>
          <g style={{ opacity: 0.2 }}>
            <path d="M229.84,54.23S229.6,59,235,60.54a6.21,6.21,0,0,0,.62.14c2.46.44,18.64,3.31,18.64,2.94V58.36H235.43S230.73,58.2,229.84,54.23Z"></path>
          </g>
          <path
            d="M318.34,165.57h-51a6.38,6.38,0,0,1-6.38-6.39V88.57a6.38,6.38,0,0,1,6.38-6.38h32.35l25,25.81v51.18A6.38,6.38,0,0,1,318.34,165.57Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <g style={{ opacity: 0.2 }}>
            <path d="M260.95,140.27h63.78a0,0,0,0,1,0,0v18.4a6.9,6.9,0,0,1-6.9,6.9h-50a6.9,6.9,0,0,1-6.9-6.9v-18.4A0,0,0,0,1,260.95,140.27Z"></path>
          </g>
          <g style={{ opacity: 0.2 }}>
            <path
              d="M299.68,82.19v19.87a5.94,5.94,0,0,0,5.94,5.94h19.1Z"
              fill={theme.body}
            ></path>
          </g>
          <g style={{ opacity: 0.2 }}>
            <path d="M299.94,103.81s-.25,4.82,5.24,6.4a5.82,5.82,0,0,0,.63.14c2.49.45,18.91,3.37,18.91,3V108h-19.1S300.85,107.84,299.94,103.81Z"></path>
          </g>
          <path
            d="M287.14,119.38l-7.66,2.8,7.66,2.78v2.88l-10.95-4.16v-3l10.95-4.16Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M295.37,110.87h3.19L291,132.53h-3.19Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M309.92,120.69v3L299,127.84V125l7.66-2.78-7.66-2.8v-2.85Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M284.53,149.68v8.57h-2v-3.51h-3.89v3.51h-2v-8.57h2v3.38h3.89v-3.38Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M288.34,151.3H285.6v-1.62h7.47v1.62h-2.75v6.95h-2Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M301.93,158.25v-5.14l-2.52,4.24h-.9L296,153.22v5h-1.86v-8.57h1.64L299,155l3.16-5.33h1.63l0,8.57Z"
            fill={theme.backgroundDetail}
          ></path>
          <path
            d="M305.82,149.68h2v7h4.3v1.61h-6.29Z"
            fill={theme.backgroundDetail}
          ></path>
        </g>
        <g id="freepik-tabs">
          <rect
            x="43.03"
            y="108.37"
            width="98.74"
            height="159.15"
            rx="3.06"
            fill={theme.body}
          ></rect>
          <path
            d="M46.64,108.37H138.3a3.47,3.47,0,0,1,3.47,3.47v14.31a0,0,0,0,1,0,0H43a0,0,0,0,1,0,0V112A3.61,3.61,0,0,1,46.64,108.37Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M138.46,267.52a4,4,0,0,0,1.27-.28,3.34,3.34,0,0,0,2-3c0-3.41,0-8.42-.05-14.87,0-12.91,0-31.58-.08-54.65s0-50.56-.06-81.07v-1.43a4.63,4.63,0,0,0-.12-1.36,3.1,3.1,0,0,0-3-2.24h-92a3,3,0,0,0-3,2.8c0,.51,0,1.08,0,1.62v44.83c0,16.6,0,32.62,0,47.88s0,29.75,0,43.32c0,3.39,0,6.73,0,10v4.86a3.15,3.15,0,0,0,2.46,3.31,15.76,15.76,0,0,0,2.3.07H63.76l54.66.08,14.86.05,3.86,0,1.32,0-1.32,0-3.86,0-14.86.05-54.66.08H48a17.67,17.67,0,0,1-2.39-.08,3.5,3.5,0,0,1-2.07-1.32,3.6,3.6,0,0,1-.71-2.38c0-1.6,0-3.22,0-4.86v-10c0-13.57,0-28.07,0-43.32s0-31.28,0-47.88q0-12.47,0-25.33v-19.5c0-.55,0-1.07,0-1.66a3.51,3.51,0,0,1,.57-1.66,3.58,3.58,0,0,1,3-1.62h92.05a3.55,3.55,0,0,1,1.51.31,3.59,3.59,0,0,1,2,2.28,5.5,5.5,0,0,1,.13,1.49v1.43c0,30.51,0,58-.06,81.07s-.06,41.74-.08,54.65c0,6.45,0,11.46-.05,14.87a3.36,3.36,0,0,1-.76,2.09,3.51,3.51,0,0,1-1.31,1,3.86,3.86,0,0,1-1,.23Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M57.59,118.65a2.92,2.92,0,1,1-2.92-2.92A2.91,2.91,0,0,1,57.59,118.65Z"
            fill={"#38b137"}
          ></path>
          <circle cx="63.64" cy="118.65" r="2.91" fill={"#fcbd05"}></circle>
          <circle cx="72.6" cy="118.65" r="2.91" fill={"#fa3913"}></circle>
          <path
            d="M86,150.26H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,150.26Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M86,160.59H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,160.59Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M86.52,188.88H68.33a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86.52a2,2,0,0,1,2,2h0A2,2,0,0,1,86.52,188.88Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M86,171.26H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,171.26Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M86,230.78H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,230.78Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M115.13,150.26H96.94a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h18.19a2,2,0,0,1,2,2h0A2,2,0,0,1,115.13,150.26Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M134.82,160.59h-38a1.89,1.89,0,0,1-1.8-2h0a1.89,1.89,0,0,1,1.8-2h38.05a1.89,1.89,0,0,1,1.8,2h0A1.89,1.89,0,0,1,134.82,160.59Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M133.79,171.26H95.49a1.91,1.91,0,0,1-1.86-2h0a1.91,1.91,0,0,1,1.86-2h38.3a1.91,1.91,0,0,1,1.86,2h0A1.91,1.91,0,0,1,133.79,171.26Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M106.12,179.89H67.82a1.91,1.91,0,0,1-1.86-2h0a1.91,1.91,0,0,1,1.86-2h38.3a1.91,1.91,0,0,1,1.86,2h0A1.91,1.91,0,0,1,106.12,179.89Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M58.9,148.29a2,2,0,1,1-2-2A2,2,0,0,1,58.9,148.29Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M58.9,138.41a2,2,0,1,1-2-2A2,2,0,0,1,58.9,138.41Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M58.9,158.59a2,2,0,1,1-2-2A2,2,0,0,1,58.9,158.59Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M58.9,169.29a2,2,0,1,1-2-2A2,2,0,0,1,58.9,169.29Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M86.52,217.76H68.33a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86.52a2,2,0,0,1,2,2h0A2,2,0,0,1,86.52,217.76Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M86,200.14H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,200.14Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M133.79,200.14H95.49a1.91,1.91,0,0,1-1.86-2h0a1.91,1.91,0,0,1,1.86-2h38.3a1.91,1.91,0,0,1,1.86,2h0A1.91,1.91,0,0,1,133.79,200.14Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M106.12,208.77H67.82a1.91,1.91,0,0,1-1.86-2h0a1.91,1.91,0,0,1,1.86-2h38.3a1.91,1.91,0,0,1,1.86,2h0A1.91,1.91,0,0,1,106.12,208.77Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M58.9,198.17a2,2,0,1,1-2-2A2,2,0,0,1,58.9,198.17Z"
            style={{ fill: "#455a64" }}
          ></path>
          <rect
            x="65.95"
            y="136.99"
            width="13.3"
            height="3.81"
            rx="0.96"
            style={{ fill: "#455a64" }}
          ></rect>
          <path
            d="M86,241.29H67.81a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2h0A2,2,0,0,1,86,241.29Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M115.13,241.29H96.94a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h18.19a2,2,0,0,1,2,2h0A2,2,0,0,1,115.13,241.29Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M58.9,239.32a2,2,0,1,1-2-2A2,2,0,0,1,58.9,239.32Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M58.9,248.82a2,2,0,1,1-2-2A2,2,0,0,1,58.9,248.82Z"
            style={{ fill: "#455a64" }}
          ></path>
          <rect
            x="65.95"
            y="247.4"
            width="13.3"
            height="3.81"
            rx="0.96"
            style={{ fill: "#455a64" }}
          ></rect>
          <rect
            x="148.36"
            y="242.95"
            width="180.35"
            height="114.72"
            fill={theme.body}
          ></rect>
          <rect
            x="148.36"
            y="242.95"
            width="32.48"
            height="114.72"
            fill={theme.body}
          ></rect>
          <path
            d="M162.64,255.33a2.14,2.14,0,0,1,.54-.23l.11-.54h.9l.14.53a2.27,2.27,0,0,1,.54.22l.46-.3.65.62-.28.47a2.14,2.14,0,0,1,.23.54l.54.11v.91l-.53.13a2,2,0,0,1-.21.55l.3.45-.63.65-.47-.28a2.17,2.17,0,0,1-.54.24l-.11.53-.91,0-.13-.53a2.27,2.27,0,0,1-.54-.22l-.46.3-.65-.63.28-.47a2.14,2.14,0,0,1-.23-.54l-.54-.11v-.9l.53-.13a2.16,2.16,0,0,1,.21-.55l-.3-.46.63-.65.47.28Zm.48,1.21a1,1,0,0,0,0,1.37,1,1,0,0,0,1.38,0,1,1,0,0,0-1.4-1.35Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M170.14,280.68H156a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h14.16a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,170.14,280.68Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M170.14,273.27H156a1.46,1.46,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h14.16a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,170.14,273.27Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M170.14,266.48H156a1.45,1.45,0,0,1-1.45-1.45h0a1.46,1.46,0,0,1,1.45-1.46h14.16A1.46,1.46,0,0,1,171.6,265h0A1.45,1.45,0,0,1,170.14,266.48Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M208.19,259.9H194.77a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h13.42a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,208.19,259.9Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M208.19,267.52H194.77a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h13.42a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,208.19,267.52Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M208.19,275.39H194.77a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h13.42a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,208.19,275.39Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M202.47,290.61h-7.7a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h7.7a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,202.47,290.61Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M202.47,298.5h-7.7a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h7.7a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,202.47,298.5Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M225.32,298.5h-14.1a1.46,1.46,0,0,1-1.46-1.46h0a1.46,1.46,0,0,1,1.46-1.45h14.1a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,225.32,298.5Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M217.35,305.51H203.24a1.45,1.45,0,0,1-1.45-1.45h0a1.46,1.46,0,0,1,1.45-1.46h14.11a1.46,1.46,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,217.35,305.51Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <rect
            x="209.79"
            y="310.61"
            width="11.67"
            height="2.91"
            rx="0.99"
            style={{ fill: "#3780FF" }}
          ></rect>
          <path
            d="M220,329.2h-8.76a1.46,1.46,0,0,1-1.46-1.46h0a1.45,1.45,0,0,1,1.46-1.45H220a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,220,329.2Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M220,345.76h-8.76a1.45,1.45,0,0,1-1.46-1.45h0a1.46,1.46,0,0,1,1.46-1.46H220a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,220,345.76Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M241.55,345.76H226.19a1.45,1.45,0,0,1-1.46-1.45h0a1.46,1.46,0,0,1,1.46-1.46h15.36a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,241.55,345.76Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M252.3,345.76h-2.21a1.45,1.45,0,0,1-1.46-1.45h0a1.46,1.46,0,0,1,1.46-1.46h2.21a1.46,1.46,0,0,1,1.46,1.46h0A1.46,1.46,0,0,1,252.3,345.76Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M240.63,329.2h-13a1.46,1.46,0,0,1-1.46-1.46h0a1.45,1.45,0,0,1,1.46-1.45h13a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,240.63,329.2Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M269.1,336.47H227.65a1.46,1.46,0,0,1-1.46-1.46h0a1.45,1.45,0,0,1,1.46-1.45H269.1a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,269.1,336.47Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M273.88,329.2H247.42a1.46,1.46,0,0,1-1.46-1.46h0a1.46,1.46,0,0,1,1.46-1.45h26.46a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,273.88,329.2Z"
            style={{ fill: "#455a64" }}
          ></path>
          <rect
            x="225.27"
            y="310.61"
            width="13.87"
            height="2.91"
            rx="0.99"
            style={{ fill: "#455a64" }}
          ></rect>
          <rect
            x="243"
            y="310.61"
            width="5.63"
            height="2.91"
            rx="0.99"
            fill={theme.avatarMisc}
          ></rect>
          <rect
            x="252.92"
            y="310.61"
            width="16.62"
            height="2.91"
            rx="0.99"
            fill={theme.avatarMisc}
          ></rect>
          <path
            d="M226.9,305.51h-3.14a1.45,1.45,0,0,1-1.46-1.45h0a1.46,1.46,0,0,1,1.46-1.46h3.14a1.46,1.46,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,226.9,305.51Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M253.52,305.51h-20a1.45,1.45,0,0,1-1.45-1.45h0a1.46,1.46,0,0,1,1.45-1.46h20a1.47,1.47,0,0,1,1.46,1.46h0A1.46,1.46,0,0,1,253.52,305.51Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M229.67,259.9H216.26a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h13.41a1.46,1.46,0,0,1,1.46,1.46h0A1.45,1.45,0,0,1,229.67,259.9Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M247,267.52H216.26a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45H247a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,247,267.52Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M280,275.39H216.26a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46H280a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,280,275.39Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M246.31,280.94H216.26a1.46,1.46,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h30.05a1.45,1.45,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,246.31,280.94Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M240.17,290.61H210.11a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h30.06a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,240.17,290.61Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M257,290.61H246.92a1.46,1.46,0,0,1-1.46-1.46h0a1.45,1.45,0,0,1,1.46-1.45H257a1.46,1.46,0,0,1,1.46,1.45h0A1.46,1.46,0,0,1,257,290.61Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M282.75,290.56h-19a1.45,1.45,0,0,1-1.46-1.45h0a1.46,1.46,0,0,1,1.46-1.46h19a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,282.75,290.56Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M308.64,290.56h-19a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h19a1.46,1.46,0,0,1,1.46,1.46h0A1.45,1.45,0,0,1,308.64,290.56Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M164,288.53h-8a1.46,1.46,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h8a1.45,1.45,0,0,1,1.45,1.45h0A1.46,1.46,0,0,1,164,288.53Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M161.61,312.07H156a1.45,1.45,0,0,1-1.45-1.46h0a1.45,1.45,0,0,1,1.45-1.45h5.63a1.45,1.45,0,0,1,1.45,1.45h0A1.45,1.45,0,0,1,161.61,312.07Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M171.6,296.37H156a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46H171.6a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,171.6,296.37Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M168.86,304.22H156a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h12.88a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,168.86,304.22Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M188.2,258.45a1.48,1.48,0,1,1-1.48-1.48A1.48,1.48,0,0,1,188.2,258.45Z"
            style={{ fill: "#455a64" }}
          ></path>
          <circle
            cx="186.72"
            cy="250.68"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <circle
            cx="186.72"
            cy="266.04"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <circle
            cx="186.72"
            cy="273.94"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <circle
            cx="186.72"
            cy="281.83"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <path
            d="M188.2,289.72a1.48,1.48,0,1,1-1.48-1.47A1.48,1.48,0,0,1,188.2,289.72Z"
            style={{ fill: "#455a64" }}
          ></path>
          <circle
            cx="186.72"
            cy="297.62"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <circle
            cx="186.72"
            cy="305.51"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <path
            d="M188.2,313.41a1.48,1.48,0,1,1-1.48-1.48A1.48,1.48,0,0,1,188.2,313.41Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M188.2,321.3a1.48,1.48,0,1,1-1.48-1.48A1.48,1.48,0,0,1,188.2,321.3Z"
            style={{ fill: "#455a64" }}
          ></path>
          <circle
            cx="186.72"
            cy="329.2"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <path
            d="M188.2,337.09a1.48,1.48,0,1,1-1.48-1.48A1.48,1.48,0,0,1,188.2,337.09Z"
            style={{ fill: "#455a64" }}
          ></path>
          <circle
            cx="186.72"
            cy="344.99"
            r="1.48"
            style={{ fill: "#455a64" }}
          ></circle>
          <path
            d="M153.77,227.31H324.88a3.83,3.83,0,0,1,3.83,3.83V243a0,0,0,0,1,0,0H148.36a0,0,0,0,1,0,0V232.71A5.4,5.4,0,0,1,153.77,227.31Z"
            fill={theme.avatarMisc}
          ></path>
          <circle cx="157.68" cy="235.61" r="2.15" fill={"#38b137"}></circle>
          <path
            d="M166.44,235.61a2.15,2.15,0,1,1-2.15-2.15A2.14,2.14,0,0,1,166.44,235.61Z"
            fill={"#fcbd05"}
          ></path>
          <path
            d="M173.05,235.61a2.15,2.15,0,1,1-2.15-2.15A2.14,2.14,0,0,1,173.05,235.61Z"
            fill={"#fa3913"}
          ></path>
          <polygon
            points="186.72 355.88 201.97 372.37 215.8 355.88 186.72 355.88"
            fill={theme.body}
          ></polygon>
          <path
            d="M323.82,357.68a6.28,6.28,0,0,0,1.58-.3,4.87,4.87,0,0,0,3.08-3.31,5,5,0,0,0,.17-1.62v-1.84c0-1.29,0-2.7,0-4.22,0-3.05,0-6.57,0-10.53,0-15.85-.05-38.77-.08-67.1q0-10.62,0-22.23,0-5.81,0-11.83c0-1,0-2,0-3a4.68,4.68,0,0,0-3.68-4.07,20.55,20.55,0,0,0-3.06-.1l-53.18,0-58.77,0h-55c-.66,0-1.37,0-2,0a4.57,4.57,0,0,0-1.81.57,4.65,4.65,0,0,0-2.23,3,13.86,13.86,0,0,0-.13,2.76v41.58q0,21.75,0,42.44v20.33q0,5,0,9.93c0,1.63,0,3.28,0,4.88a4.63,4.63,0,0,0,2.33,3.75,4.57,4.57,0,0,0,2.19.61h34.77l.07.07,14,14.7h-.31L214,357.55l.06-.07h.09l80.17.08,21.89.06,5.7,0,1.94,0-1.94,0-5.7,0-21.89.06-80.17.08.15-.07L202,372.5l-.15.18-.16-.17-14-14.68.16.07h-34.7a5.22,5.22,0,0,1-2.4-.67,5.13,5.13,0,0,1-2.58-4.14c0-1.66,0-3.27,0-4.91v-9.93q0-10,0-20.33V275.49q0-10.87,0-22v-21a6.12,6.12,0,0,1,.14-1.46,5.21,5.21,0,0,1,2.48-3.31,5.15,5.15,0,0,1,2-.64c.73-.05,1.36,0,2,0H268.55l53.18,0a19.56,19.56,0,0,1,3.14.11,5.1,5.1,0,0,1,4,4.44c.06,1.06,0,2,0,3q0,6,0,11.83,0,11.6,0,22.23c0,28.33-.07,51.25-.09,67.1,0,4,0,7.48,0,10.53,0,1.52,0,2.93,0,4.22v1.84a5.19,5.19,0,0,1-.19,1.65,4.81,4.81,0,0,1-1.45,2.33,4.7,4.7,0,0,1-1.71,1,4.82,4.82,0,0,1-1.19.24Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M162.53,128.49H298.77a3.17,3.17,0,0,1,3.17,3.17v68.53a3.79,3.79,0,0,1-3.79,3.79H162.53a3.17,3.17,0,0,1-3.17-3.17V131.66A3.17,3.17,0,0,1,162.53,128.49Z"
            fill={theme.body}
          ></path>
          <path
            d="M162.83,128.49H298.47a3.47,3.47,0,0,1,3.47,3.47V144.7a0,0,0,0,1,0,0H159.36a0,0,0,0,1,0,0V132A3.47,3.47,0,0,1,162.83,128.49Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M297.93,204a6.79,6.79,0,0,0,1.09-.19,3.94,3.94,0,0,0,2.41-2,4.32,4.32,0,0,0,.44-2.3c0-.89,0-1.85,0-2.9,0-2.09,0-4.51,0-7.23,0-10.88,0-26.61-.08-46.05q0-3.65,0-7.47c0-1.27,0-2.55,0-3.85a3,3,0,0,0-1.73-3,4,4,0,0,0-1.87-.28H283.59l-36.5,0-83.64,0a5.63,5.63,0,0,0-1.32.09,3.26,3.26,0,0,0-2.47,2.66,8.39,8.39,0,0,0,0,1.34v15q0,10.83,0,21.34v20.6q0,5,0,10a3.8,3.8,0,0,0,2.35,3.65,5.51,5.51,0,0,0,2.31.27h2.42l68.31,0,46.05.08,12.52,0,3.26,0,1.11,0-1.11,0-3.26,0-12.52.05-46.05.08-68.31.06h-2.42a6.17,6.17,0,0,1-2.49-.31,4.31,4.31,0,0,1-2.66-4.1v-10c0-6.73,0-13.61,0-20.6V132.84a8.58,8.58,0,0,1,.05-1.42,3.73,3.73,0,0,1,2.86-3.08,5.51,5.51,0,0,1,1.44-.11l83.64,0,36.5,0h14.55a4.16,4.16,0,0,1,2,.32,3.44,3.44,0,0,1,2,3.39c0,1.3,0,2.58,0,3.85q0,3.83,0,7.47c0,19.44-.06,35.17-.08,46.05,0,2.72,0,5.14,0,7.23,0,1.05,0,2,0,2.9a5.86,5.86,0,0,1-.08,1.27,4.45,4.45,0,0,1-.39,1.08,4.09,4.09,0,0,1-2.5,2A3.29,3.29,0,0,1,297.93,204Z"
            fill={theme.avatarMiscKey}
          ></path>
          <circle cx="169.97" cy="137.86" r="2.66" fill={"#38b137"}></circle>
          <path
            d="M180.79,137.86a2.66,2.66,0,1,1-2.65-2.66A2.65,2.65,0,0,1,180.79,137.86Z"
            fill={"#fcbd05"}
          ></path>
          <circle cx="186.31" cy="137.86" r="2.66" fill={"#fa3913"}></circle>
          <path
            d="M200.44,167.54H183.87a1.79,1.79,0,0,1-1.8-1.79h0a1.79,1.79,0,0,1,1.8-1.8h16.57a1.8,1.8,0,0,1,1.8,1.8h0A1.8,1.8,0,0,1,200.44,167.54Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M200.44,177H183.87a1.8,1.8,0,0,1-1.8-1.8h0a1.8,1.8,0,0,1,1.8-1.8h16.57a1.81,1.81,0,0,1,1.8,1.8h0A1.81,1.81,0,0,1,200.44,177Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M287.94,167.54H239.86a1.8,1.8,0,0,1-1.8-1.79h0a1.8,1.8,0,0,1,1.8-1.8h48.08a1.8,1.8,0,0,1,1.8,1.8h0A1.8,1.8,0,0,1,287.94,167.54Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M200.44,186.69H183.87a1.8,1.8,0,0,1-1.8-1.8h0a1.79,1.79,0,0,1,1.8-1.8h16.57a1.8,1.8,0,0,1,1.8,1.8h0A1.81,1.81,0,0,1,200.44,186.69Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M276.47,177H259.89a1.8,1.8,0,0,1-1.79-1.8h0a1.8,1.8,0,0,1,1.79-1.8h16.58a1.81,1.81,0,0,1,1.8,1.8h0A1.81,1.81,0,0,1,276.47,177Z"
            style={{ fill: "#3780FF" }}
          ></path>
          <path
            d="M227,167.54H210.42a1.8,1.8,0,0,1-1.8-1.79h0a1.8,1.8,0,0,1,1.8-1.8H227a1.79,1.79,0,0,1,1.79,1.8h0A1.79,1.79,0,0,1,227,167.54Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M248.35,177H210.42a1.81,1.81,0,0,1-1.8-1.8h0a1.81,1.81,0,0,1,1.8-1.8h37.93a1.8,1.8,0,0,1,1.8,1.8h0A1.8,1.8,0,0,1,248.35,177Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M285.56,186.69H210.34a1.76,1.76,0,0,1-1.72-1.8h0a1.76,1.76,0,0,1,1.72-1.8h75.22a1.75,1.75,0,0,1,1.71,1.8h0A1.76,1.76,0,0,1,285.56,186.69Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M247.56,193.54H210.42a1.81,1.81,0,0,1-1.8-1.8h0a1.81,1.81,0,0,1,1.8-1.8h37.14a1.8,1.8,0,0,1,1.8,1.8h0A1.8,1.8,0,0,1,247.56,193.54Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M175.74,165.75a1.83,1.83,0,1,1-1.83-1.83A1.83,1.83,0,0,1,175.74,165.75Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M175.74,156.74a1.83,1.83,0,1,1-1.83-1.83A1.82,1.82,0,0,1,175.74,156.74Z"
            style={{ fill: "#455a64" }}
          ></path>
          <circle
            cx="173.91"
            cy="175.13"
            r="1.83"
            style={{ fill: "#455a64" }}
          ></circle>
          <circle
            cx="173.91"
            cy="184.89"
            r="1.83"
            style={{ fill: "#455a64" }}
          ></circle>
          <rect
            x="182.44"
            y="154.58"
            width="12.12"
            height="3.47"
            rx="0.96"
            style={{ fill: "#455a64" }}
          ></rect>
        </g>
        <g id="freepik-drink">
          <path
            d="M89.53,439c-8.34,3.63-16.38,4-24,0l-5.57-46.73H94.59Z"
            style={{ fill: "#6f4439" }}
          ></path>
          <path
            d="M92.3,389.57l-1-3.34L65.15,383a1.53,1.53,0,0,0-1.62,1l-1.89,5.51H58.3v6.05c13.11,2.31,25.66,2.44,37.55,0v-6.05Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M92.3,389.57a.68.68,0,0,1,.24,0l.69,0,2.62,0h.09v.1c0,1.56,0,3.6.06,6.05v.12l-.12,0a87.59,87.59,0,0,1-16.12,1.84A110.12,110.12,0,0,1,58.4,395.9l-.14,0-.22,0v-6.53h3.6l-.24.17c.63-1.84,1.26-3.64,1.86-5.4a1.8,1.8,0,0,1,1-1.15,1.85,1.85,0,0,1,.77-.14c.26,0,.46.06.69.08l2.64.33L78,384.41l13.3,1.72h.06l0,.06c.3,1.05.54,1.88.72,2.48.07.26.13.48.18.65a1.52,1.52,0,0,1,0,.24.78.78,0,0,1-.08-.2l-.22-.62c-.21-.61-.48-1.43-.84-2.49l.08.07L77.92,384.8l-9.59-1.13-2.64-.32a2.26,2.26,0,0,0-1.22,0,1.29,1.29,0,0,0-.74.86l-1.85,5.41-.06.17H58.3l.26-.26v6.05l-.21-.26.14,0a112.7,112.7,0,0,0,21.26,1.77,91.46,91.46,0,0,0,16.06-1.69l-.12.16c0-2.45,0-4.49.06-6.05l.1.1-2.67-.06-.67,0Z"
            fill={theme.avatarMiscKey}
          ></path>
          <path
            d="M92,423.52c-9.06,1.46-19,1.25-29.63,0l-1.44-14.17a146.91,146.91,0,0,0,32.81,0Z"
            style={{ fill: "#ae7461" }}
          ></path>
          <path
            d="M75.31,413.9a3.14,3.14,0,0,0-1.22,4l.94,2a3.14,3.14,0,0,0,3.86,1.65Z"
            style={{ fill: "#6f4439" }}
          ></path>
          <path
            d="M75.94,413.61l3.58,7.7a3.16,3.16,0,0,0,1.22-4l-.94-2A3.15,3.15,0,0,0,75.94,413.61Z"
            style={{ fill: "#6f4439" }}
          ></path>
          <path
            d="M92.59,389.66a5.68,5.68,0,0,1-1.18.35c-.76.2-1.88.44-3.28.69a63.25,63.25,0,0,1-22.07,0c-1.39-.26-2.51-.5-3.28-.71a6.32,6.32,0,0,1-1.18-.36,5.64,5.64,0,0,1,1.22.16c.78.15,1.91.34,3.3.54a75.22,75.22,0,0,0,21.95,0c1.4-.2,2.53-.39,3.3-.53A5.26,5.26,0,0,1,92.59,389.66Z"
            fill={theme.avatarMiscKey}
          ></path>
        </g>
        <g id="freepik--Device--inject-2">
          <path
            d="M110.4,320.16s-5.71,1.23-6.7,5.29,8,101,8.31,103.35c.57,3.71,3.7,8.43,10.74,8.17,6.15-.22,89.86,0,110.93,0,2.81,0,4.49-2.38,4.86-5.22h0l-103.65-6.12Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M107.8,323.4l9,104.54a3.39,3.39,0,0,0,3.31,3.05l118.45.53-22.76-22.58-76.94-.54-8.23-81.91a2.57,2.57,0,0,0-2-2.23l-17.24-4A3,3,0,0,0,107.8,323.4Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M107.8,323.4a2.45,2.45,0,0,1,0-.51,2.78,2.78,0,0,1,.46-1.41,3,3,0,0,1,2-1.33,3.76,3.76,0,0,1,1.58.13l1.75.4,9.15,2.1,5.8,1.34a2.72,2.72,0,0,1,2.19,2.33c.11,1.12.23,2.27.35,3.45,1.93,19,4.68,46,8,78.48l-.22-.21,76.95.51h.1l.08.08,22.75,22.57.45.45h-.63l-5.25,0-86.83-.41-19.5-.1-4.7,0c-.78,0-1.54,0-2.34,0a3.6,3.6,0,0,1-2.22-.95,3.55,3.55,0,0,1-1.11-2.12c-.09-.77-.14-1.51-.21-2.27-.12-1.5-.25-3-.38-4.47-1-11.82-1.94-22.84-2.79-32.89-1.69-20.11-3.06-36.38-4-47.63-.46-5.62-.82-10-1.06-12.95-.12-1.48-.2-2.6-.26-3.37s-.08-1.15-.08-1.15.06.38.13,1.14.18,1.9.31,3.37c.27,3,.66,7.33,1.16,12.94,1,11.25,2.42,27.52,4.18,47.61.87,10.06,1.83,21.07,2.85,32.89q.19,2.22.39,4.47c.07.75.12,1.52.2,2.25a3.28,3.28,0,0,0,1,1.86,3.2,3.2,0,0,0,2,.82c.75,0,1.54,0,2.31,0l4.71,0,19.5.08,86.82.37,5.25,0-.19.44L215.6,409.12l.18.08-76.94-.58h-.2l0-.2c-3.23-32.48-5.91-59.54-7.79-78.49-.12-1.18-.24-2.33-.35-3.45a2.48,2.48,0,0,0-2-2.14l-5.8-1.36-9.13-2.17-1.75-.41a3.6,3.6,0,0,0-1.53-.15,3,3,0,0,0-2,1.27,2.8,2.8,0,0,0-.48,1.38C107.79,323.23,107.82,323.4,107.8,323.4Z"
            fill={theme.avatarMiscKey}
          ></path>
          <polygon
            points="194.5 428.8 205.51 428.8 191.59 410.75 185.06 410.75 194.5 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <path
            d="M111.87,328.12,120.28,419,136,406.5,129.2,330a1.32,1.32,0,0,0-1.13-1.2l-14.69-2.1A1.33,1.33,0,0,0,111.87,328.12Z"
            fill={theme.avatarMiscKey}
          ></path>
          <polygon
            points="128.7 428.8 140.06 428.8 151.61 410.75 145.07 410.75 128.7 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <polygon
            points="142.65 428.8 153.66 428.8 159.69 410.75 153.15 410.75 142.65 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <polygon
            points="156.12 428.8 167.13 428.8 167.72 410.75 161.19 410.75 156.12 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <polygon
            points="168.62 428.8 179.63 428.8 175.56 410.75 169.03 410.75 168.62 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <polygon
            points="181.64 428.8 192.65 428.8 183.68 410.75 177.15 410.75 181.64 428.8"
            fill={theme.avatarMiscKey}
          ></polygon>
          <path
            d="M205,425.28c0,.09-16.79.16-37.5.16s-37.49-.07-37.49-.16,16.78-.15,37.49-.15S205,425.2,205,425.28Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M203,422c0,.09-15.94.16-35.61.16s-35.61-.07-35.61-.16,15.94-.15,35.61-.15S203,421.89,203,422Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M203,419c0,.17-15,.31-33.5.31S136,419.14,136,419s15-.31,33.51-.31S203,418.8,203,419Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M203.42,416.23c0,.17-14.68.31-32.78.31s-32.79-.14-32.79-.31,14.68-.32,32.79-.32S203.42,416.05,203.42,416.23Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M205,414.23c0,.17-14.78.31-33,.31s-33-.14-33-.31,14.77-.32,33-.32S205,414.05,205,414.23Z"
            fill={theme.avatarMisc}
          ></path>
          <path
            d="M206.58,412.13c0,.18-15.05.32-33.61.32s-33.62-.14-33.62-.32,15-.31,33.62-.31S206.58,412,206.58,412.13Z"
            fill={theme.avatarMisc}
          ></path>
          <path d="M118.44,427.94,139,408.75" fill={theme.avatarMisc}></path>
          <path
            d="M139,408.75c.1.1-4.42,4.48-10.08,9.78s-10.34,9.52-10.44,9.41,4.41-4.48,10.08-9.79S138.86,408.64,139,408.75Z"
            fill={theme.avatarMiscKey}
          ></path>
        </g>
        <g id="freepik--Character--inject-2">
          <path
            d="M478.51,337.24s-1-10.62-27.46-17.41c-12.77-3.28-43.26-4.85-46.71,15-.11.64-15.18,125.27-15.18,125.27h56.37Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M469.58,460.06h-26L452.26,338c.64-5.94,6.79-10.23,13.74-9.57l2.33.22c6.94.66,11.35,7.35,11.15,15Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M445.53,455.67c-.15,0,1.69-24.07,4.1-53.72s4.48-53.69,4.62-53.68-1.69,24.06-4.1,53.72S445.67,455.68,445.53,455.67Z"
            fill={"#212121"}
          ></path>
          <path
            d="M473.52,331.47a27.2,27.2,0,0,0-3.66-.86,13.71,13.71,0,0,0-14.51,8.32,28.11,28.11,0,0,0-1.1,3.59,3.45,3.45,0,0,1,.05-1,12,12,0,0,1,.71-2.71,13.52,13.52,0,0,1,14.9-8.54,11.85,11.85,0,0,1,2.7.75A3.38,3.38,0,0,1,473.52,331.47Z"
            fill={"#212121"}
          ></path>
          <path
            d="M391.41,178.81c.27-7.15-4.58-8.11-8.31-7.29a12.65,12.65,0,0,0-15.55.85,11.25,11.25,0,0,0-16.64,9.79,9.4,9.4,0,0,0-7.64,16.12,9,9,0,0,0,4.43,17.43l43.86-29.21C394.19,183.73,391.27,182.63,391.41,178.81Z"
            fill={"#212121"}
          ></path>
          <path
            d="M346.43,217a38.34,38.34,0,0,1,36.94-36.59l2-.07c21.43.49,33,19,31.91,40.38l-.56,71.34-.14,13.55-49,11.65-.5-28.24s-20.68-2.46-21.66-23C344.91,256.1,345.6,235.58,346.43,217Z"
            fill={theme.skinColor}
          ></path>
          <path
            d="M366.88,289a49.94,49.94,0,0,0,26.66-8.84s-5.81,15-26.39,13.9Z"
            fill={theme.skinColor2}
          ></path>
          <path
            d="M352.42,237.76a2.93,2.93,0,0,0,2.93,2.86,2.81,2.81,0,0,0,2.93-2.76,2.93,2.93,0,0,0-2.93-2.86A2.8,2.8,0,0,0,352.42,237.76Z"
            fill={"#212121"}
          ></path>
          <path
            d="M351,228c.37.36,2.54-1.36,5.7-1.46s5.53,1.39,5.86,1c.16-.18-.23-.86-1.27-1.55a8,8,0,0,0-4.71-1.27,7.68,7.68,0,0,0-4.52,1.64C351.13,227.11,350.83,227.81,351,228Z"
            fill={"#212121"}
          ></path>
          <path
            d="M377.81,237.11a2.94,2.94,0,0,0,2.94,2.86,2.81,2.81,0,0,0,2.93-2.76,2.93,2.93,0,0,0-2.93-2.86A2.81,2.81,0,0,0,377.81,237.11Z"
            fill={"#212121"}
          ></path>
          <path
            d="M381.23,226.46c.37.37,2.54-1.35,5.7-1.46s5.53,1.4,5.86,1c.16-.18-.23-.86-1.27-1.56a8.1,8.1,0,0,0-4.71-1.27,7.8,7.8,0,0,0-4.52,1.64C381.35,225.59,381,226.3,381.23,226.46Z"
            fill={"#212121"}
          ></path>
          <path
            d="M371.65,250.25c0-.18-2-.45-5.16-.75-.81-.05-1.58-.18-1.74-.73a4.1,4.1,0,0,1,.46-2.41q1-3,2.16-6.23c3-8.86,5.15-16.14,4.79-16.26s-3.08,7-6.08,15.84L364,246a4.71,4.71,0,0,0-.31,3.18,2,2,0,0,0,1.37,1.13,5.51,5.51,0,0,0,1.38.14C369.66,250.48,371.65,250.43,371.65,250.25Z"
            fill={"#212121"}
          ></path>
          <path
            d="M381,252.1c-.52,0-.4,3.42-3.25,6s-6.51,2.29-6.52,2.77c0,.22.83.64,2.38.62a8.5,8.5,0,0,0,5.45-2.12,7.43,7.43,0,0,0,2.51-5C381.67,252.91,381.26,252.07,381,252.1Z"
            fill={"#212121"}
          ></path>
          <path
            d="M380.86,217.44c.41.8,3.1,0,6.41-.09s6.09.52,6.4-.3c.13-.4-.45-1.13-1.63-1.78a9.85,9.85,0,0,0-5-1.12,9.58,9.58,0,0,0-4.81,1.41C381.18,216.29,380.68,217.05,380.86,217.44Z"
            fill={"#212121"}
          ></path>
          <path
            d="M351.42,219.28c.53.75,2.74.1,5.35.19s4.81.71,5.36,0c.24-.35-.08-1.09-1-1.81a7.76,7.76,0,0,0-8.63-.14C351.53,218.2,351.19,218.92,351.42,219.28Z"
            fill={"#212121"}
          ></path>
          <path
            d="M348.07,207.52s5-13.07,11.07-16.29c7.39-3.91,10.92,4.23,18,2s14.14-6,21.66-.72c8.22,5.77,4.62,10.16,5.44,19.26,3.32,36.92,10.45,47.13,11.75,29.64.14-1.91,5.39-3.62,5.88-11.71.65-10.49.6-23.2-2-30.54-2.19-6.18-5.91-10.38-12.47-14.92-10.36-7.16-24.23-6.83-33.62-5.54S346.64,191.43,348.07,207.52Z"
            fill={"#212121"}
          ></path>
          <path
            d="M414.67,240.87a5.58,5.58,0,0,1,5.83-5.59c3.85.23,8.2,2.25,8.22,10.19.06,14.07-14.08,11.22-14.11,10.81S414.64,246.76,414.67,240.87Z"
            fill={theme.skinColor}
          ></path>
          <path
            d="M417.65,251c.07,0,.25.17.67.36a2.49,2.49,0,0,0,1.83,0c1.48-.55,2.73-2.92,2.78-5.45a8.06,8.06,0,0,0-.69-3.51,2.86,2.86,0,0,0-1.86-1.88,1.24,1.24,0,0,0-1.44.68c-.19.39-.1.67-.17.7s-.3-.24-.19-.82a1.51,1.51,0,0,1,.55-.88,1.79,1.79,0,0,1,1.35-.36,3.42,3.42,0,0,1,2.57,2.17,8.29,8.29,0,0,1,.86,3.92c-.07,2.83-1.52,5.49-3.55,6.09a2.63,2.63,0,0,1-2.24-.37C417.68,251.32,417.61,251,417.65,251Z"
            fill={theme.skinColor2}
          ></path>
          <path
            d="M413.43,213.65c-1.78,1.31-3.67,2.66-5.86,3s-4.75-.88-5.2-3.05c-.2-1,0-2.11-.67-2.84-.8-.88-2.21-.55-3.38-.38a9,9,0,0,1-9.54-6.1c-1,1.6-3.42,1.61-5,.65s-2.69-2.58-4-3.95-3.06-2.58-4.91-2.25c-1,.19-2,.84-3,.79-1.86-.1-2.83-2.23-3.18-4s-.64-4-2.23-4.9-3.35-.27-5,0-3.89,0-4.39-1.65a3.31,3.31,0,0,1,.54-2.57,9.09,9.09,0,0,1,10.62-3.83,12.35,12.35,0,0,1,22.59-3.2,12.54,12.54,0,0,1,20.18,3.85A9.08,9.08,0,0,1,421.62,196c2.58,1.36,4.83,3.79,5,6.7s-2.58,5.9-5.41,5.24a5,5,0,0,1-8.52,5.32"
            fill={"#212121"}
          ></path>
          <path
            d="M422.23,207.88a3.4,3.4,0,0,1-.78,2.73,4.48,4.48,0,0,1-2.76,1.78,4.85,4.85,0,0,1-4.05-1l.59-.11a13.91,13.91,0,0,1-2.68,3.61,8,8,0,0,1-2.5,1.68,6.26,6.26,0,0,1-7-1.82,8,8,0,0,1-1.24-2.88,2.7,2.7,0,0,1-.15-1.13c.06,0,.19.36.45,1a9.27,9.27,0,0,0,1.39,2.63c1.41,2,5.78,2.85,8.46-.1a14.24,14.24,0,0,0,2.55-3.38l.24-.42.36.31a4.24,4.24,0,0,0,3.42,1,4.16,4.16,0,0,0,2.5-1.43A7,7,0,0,0,422.23,207.88Z"
            style={{ fill: "#455a64" }}
          ></path>
          <path
            d="M256,392s-26.53-.79-28.12-1.29a16.86,16.86,0,0,0-7.35,0c-1.89.6-9.44,3.28-9.44,3.28l-16,4.27S179,408.75,179.32,410.86s2.09,3.55,5,2.46,17.42-8.65,17.42-8.65l16-1.69s21.82,7.84,26.23,7.45,15.7-2.3,15.7-2.3,1.59-11.31.79-13.1S256,392,256,392Z"
            fill={theme.skinColor}
          ></path>
          <path
            d="M180.47,420.22s13.45-14.41,17.17-16.35,24.46-5.35,24.46-5.35,6.64-2.75,9.07-1.78,28.18,4.86,29.31,5-5.5,22.68-5.5,22.68a52.51,52.51,0,0,1-19.28-.81c-10.36-2.43-13.93-5.67-13.93-5.67l-10.34-2.72-6.34,2.41s-1.13,9.38-5.67,8.89l-2.1-1.46,1.62-10.36-6.64,2.75s-5.19,7.78-9.07,6.64S180.47,420.22,180.47,420.22Z"
            fill={theme.skinColor}
          ></path>
          <path
            d="M180.47,420.22a3.48,3.48,0,0,0-.1,1.13,2.5,2.5,0,0,0,.5,1.26,3.69,3.69,0,0,0,1.52,1.11,3.83,3.83,0,0,0,2.33.34,7.17,7.17,0,0,0,2.58-1.31,23.65,23.65,0,0,0,4.88-5.36l0,0,0,0,6.63-2.77.27-.12,0,.29c-.48,3.12-1,6.62-1.59,10.37l-.08-.18,2.1,1.45-.09,0a3,3,0,0,0,2.34-.85,7,7,0,0,0,1.55-2.25,20.58,20.58,0,0,0,1.55-5.61l0-.13.12-.05,6.32-2.42.07,0,.07,0,10.36,2.7,0,0,.06,0a27,27,0,0,0,7.16,3.65,60.7,60.7,0,0,0,8.15,2.27,53,53,0,0,0,8.7,1,49.35,49.35,0,0,0,9-.5l-.2.18c.46-1.59,1-3.29,1.44-5,1.43-5.1,2.86-10.11,3.89-15.07a17.12,17.12,0,0,0,.29-1.84,2.94,2.94,0,0,0,0-.42c0-.07-.06-.1,0,0h0l-.12,0-.93-.14-3.7-.56-7.25-1.14c-4.75-.77-9.36-1.53-13.79-2.37-1.1-.23-2.18-.42-3.28-.75a6.06,6.06,0,0,0-3.11-.1,31.83,31.83,0,0,0-6,1.8h-.05c-7.24,1.21-13.87,2.41-19.66,3.79a36,36,0,0,0-4.11,1.21,12.46,12.46,0,0,0-3.27,2.21c-2,1.66-3.66,3.3-5.19,4.78-3.06,3-5.38,5.38-7,7l-1.81,1.87c-.42.42-.64.62-.64.62s.19-.23.59-.67l1.76-1.91c1.57-1.65,3.87-4.09,6.9-7.09,1.52-1.49,3.21-3.15,5.17-4.83.49-.42,1-.85,1.54-1.25a7.62,7.62,0,0,1,1.8-1,36.69,36.69,0,0,1,4.16-1.24c5.8-1.42,12.42-2.64,19.67-3.89l-.05,0a32.38,32.38,0,0,1,6.09-1.85,6.59,6.59,0,0,1,3.35.11c1,.31,2.12.5,3.21.73,4.41.83,9,1.57,13.78,2.33l7.25,1.13,3.7.57.93.14h.2l.11,0a.52.52,0,0,1,.23.4,3,3,0,0,1,0,.52,16.89,16.89,0,0,1-.29,1.91c-1,5-2.47,10-3.9,15.11-.49,1.72-1,3.43-1.44,5l0,.16-.16,0a48.88,48.88,0,0,1-9.09.5,51.87,51.87,0,0,1-8.78-1,58.89,58.89,0,0,1-8.22-2.3,37.22,37.22,0,0,1-3.83-1.61,18.67,18.67,0,0,1-3.49-2.14l.1.05-10.34-2.73h.14l-6.34,2.41.14-.18a20.7,20.7,0,0,1-1.61,5.74,7.23,7.23,0,0,1-1.65,2.37,3.44,3.44,0,0,1-2.65.95h-.05l0,0-2.1-1.46-.1-.07,0-.12c.59-3.74,1.15-7.24,1.64-10.36l.23.18-6.65,2.73.06-.05a23.57,23.57,0,0,1-5,5.38,7.37,7.37,0,0,1-2.67,1.31,3.93,3.93,0,0,1-2.43-.38,3.69,3.69,0,0,1-1.55-1.18,2.54,2.54,0,0,1-.49-1.32A2.25,2.25,0,0,1,180.47,420.22Z"
            fill={theme.skinColor2}
          ></path>
          <path
            d="M221.18,408a4.39,4.39,0,0,1-1,0c-.65-.05-1.59-.14-2.76-.26l-4.08-.46-1.18-.13a3.54,3.54,0,0,0-1.17,0,24.57,24.57,0,0,0-2.52.52c-3.37,1-6.75,1.63-8,3.63a7.65,7.65,0,0,0-1.2,2.42c-.18.62-.23,1-.26,1a2.21,2.21,0,0,1,.07-1,6.71,6.71,0,0,1,1.1-2.58,6.06,6.06,0,0,1,1.37-1.41,7.6,7.6,0,0,1,2-1,50.5,50.5,0,0,1,4.83-1.49,22.31,22.31,0,0,1,2.58-.53,4.36,4.36,0,0,1,1.3,0l1.17.16,4.08.56c1.16.17,2.09.32,2.74.43A3.39,3.39,0,0,1,221.18,408Z"
            fill={theme.skinColor2}
          ></path>
          <path
            d="M185.08,415.43a9.37,9.37,0,0,1,1.11-1.12c.75-.69,1.85-1.66,3.24-2.82s3.08-2.52,5-3.93c1-.7,2-1.42,3.14-2.1a18.89,18.89,0,0,1,1.79-.94,15.83,15.83,0,0,1,2-.63,104.79,104.79,0,0,1,14-2.4l4.26-.53a9.58,9.58,0,0,1,1.57-.13,8.49,8.49,0,0,1-1.54.33l-4.23.69c-3.57.63-8.58,1.07-13.9,2.54a15.24,15.24,0,0,0-1.91.61,17.86,17.86,0,0,0-1.74.9c-1.11.66-2.14,1.36-3.12,2.05-2,1.38-3.65,2.7-5.07,3.82l-3.34,2.69A10.16,10.16,0,0,1,185.08,415.43Z"
            fill={theme.skinColor2}
          ></path>
          <path
            d="M352.61,321.5a154.63,154.63,0,0,0-12.67,21.38c-4.81,10-20.35,44.05-20.35,44.05l-63.33,3.32.94,24.47s61.16,21.79,74.85,17.58,28.28-20,28.28-20l6.55-103.2S361.45,308.09,352.61,321.5Z"
            fill={theme.imageClothes}
          ></path>
          <path
            d="M365.85,309.35s-16.24,36.83-18.61,52.43c-4.71,31-6.29,50.35-6.29,50.35l-5.52,47.93H443.58s3.58-88.83,1.28-118.61c-1.72-22.32-18.82-36.22-25.81-43.85-.42-.46-2.34-6.71-2.34-6.71l-50.4,12.91Z"
            fill={theme.imageClothes}
          ></path>
          <path
            d="M360.62,314.94a1.35,1.35,0,0,1-.12.35c-.11.25-.24.59-.42,1l-1.61,3.85c-1.39,3.34-3.4,8.19-5.42,14.35a170.85,170.85,0,0,0-5.59,22c-1.61,8.41-2.91,17.74-4,27.57-2,19.68-3.79,37.49-5.1,50.41-.67,6.43-1.21,11.64-1.59,15.27-.2,1.78-.35,3.17-.46,4.15-.06.46-.1.81-.13,1.08a2.41,2.41,0,0,1-.07.37,2.58,2.58,0,0,1,0-.37c0-.27.05-.63.08-1.09.09-1,.21-2.37.36-4.16.34-3.64.83-8.85,1.43-15.29,1.25-12.9,2.9-30.74,5-50.43,1.08-9.83,2.4-19.18,4-27.6a166.47,166.47,0,0,1,5.71-22.06c2.07-6.15,4.13-11,5.58-14.31.72-1.67,1.31-2.95,1.71-3.81.19-.42.34-.74.46-1A3,3,0,0,1,360.62,314.94Z"
            fill={"#212121"}
          ></path>
          <g style={{ opacity: 0.30000000000000004 }}>
            <path d="M416.81,372.92c-1-9.81-5.41-19.66-13.48-25.32l-65.94,94.16c11.64,6.28,24.57,12.76,37.5,10,7-1.49,13.14-5.59,18.16-10.64,10.43-10.49,16.09-24.78,20.06-39C415.76,392.6,417.8,382.73,416.81,372.92Z"></path>
          </g>
          <path
            d="M424.28,361.55c-8.21,29.07-26.7,64.54-32.38,71.67a44.09,44.09,0,0,1-34.5,16.93c-7.44,0-70.32-12.25-89-15.91a8,8,0,0,1-6.27-6.19l-.16-.77L253.62,425l4.47-22.51.89-1.25,89.93,2.83h0l26.16-72.87c.5-.83,8.05-25.28,28.54-24.17"
            fill={theme.imageClothes}
          ></path>
          <path
            d="M403.61,307.05h-.68a18.78,18.78,0,0,0-2,.05,22.55,22.55,0,0,0-7.56,1.79,27.44,27.44,0,0,0-9.87,7.61,49.52,49.52,0,0,0-8.3,14.79v0c-6.61,18.46-15.58,43.54-26.09,72.89l-.05.16h-.17L259,401.52l.22-.11-.89,1.25,0-.1c-1.29,6.51-2.66,13.43-4,20.43l-.41,2.08-.19-.31,8.39,2.26.16.05,0,.15a8.17,8.17,0,0,0,2.47,4.85,7.77,7.77,0,0,0,2.3,1.47,13.78,13.78,0,0,0,2.69.69l11,2.15,21.5,4.13c14.09,2.67,27.66,5.21,40.61,7.4,3.24.54,6.44,1,9.6,1.5,1.58.2,3.16.42,4.71.49a38.07,38.07,0,0,0,4.66-.23,44.22,44.22,0,0,0,17-5.45,45.38,45.38,0,0,0,12.69-10.82,61.9,61.9,0,0,0,4.36-6.58c1.31-2.23,2.55-4.44,3.72-6.61a355.43,355.43,0,0,0,19.28-42.49c1.9-5.16,3.26-9.21,4.12-12,.42-1.37.75-2.42,1-3.14s.34-1.06.34-1.06-.09.36-.29,1.07l-.92,3.16c-.83,2.77-2.16,6.83-4,12a348.71,348.71,0,0,1-19.16,42.59c-1.16,2.18-2.4,4.39-3.7,6.63a60.41,60.41,0,0,1-4.39,6.64A45.59,45.59,0,0,1,379,444.59a44.62,44.62,0,0,1-17.15,5.53,37,37,0,0,1-4.72.24c-1.6-.07-3.17-.29-4.76-.49-3.17-.45-6.38-1-9.62-1.49-13-2.18-26.53-4.71-40.63-7.37l-21.5-4.13-11-2.14a14.05,14.05,0,0,1-2.78-.72,8.06,8.06,0,0,1-2.46-1.57,8.7,8.7,0,0,1-2.63-5.12l.19.2-8.39-2.26-.23-.07,0-.23c.13-.66.28-1.37.42-2.08,1.39-7,2.76-13.92,4-20.43v-.06l0,0,.89-1.25.08-.11H259l89.93,2.86-.22.15,26.24-72.84v0a49.22,49.22,0,0,1,8.38-14.78,27.37,27.37,0,0,1,10-7.61,22.3,22.3,0,0,1,7.61-1.73,14.94,14.94,0,0,1,2,0Z"
            fill={"#212121"}
          ></path>
          <path
            d="M329.1,398.32a33.15,33.15,0,0,0-4.34-6.36,32.72,32.72,0,0,0-6-4.78,6.94,6.94,0,0,1,2.09.95,19.19,19.19,0,0,1,4.33,3.47,19.46,19.46,0,0,1,3.16,4.56A7.47,7.47,0,0,1,329.1,398.32Z"
            fill={"#212121"}
          ></path>
          <path
            d="M359.79,421.72c-.09,0-.27-1.15-.79-3a28.47,28.47,0,0,0-2.95-6.72,27.91,27.91,0,0,0-4.63-5.69c-1.37-1.3-2.33-2-2.28-2.06a2.66,2.66,0,0,1,.73.41,18,18,0,0,1,1.8,1.37,24.36,24.36,0,0,1,7.69,12.6,15.24,15.24,0,0,1,.39,2.23A2.77,2.77,0,0,1,359.79,421.72Z"
            fill={"#212121"}
          ></path>
          <g style={{ opacity: 0.30000000000000004 }}>
            <path d="M335.8,403.52l5.23.2c.85-20.55,5.08-41,8.7-61.27C340.5,361.31,338.08,382.66,335.8,403.52Z"></path>
          </g>
          <path
            d="M417.3,297.6a1.81,1.81,0,0,1-.5.17l-1.44.4-5.3,1.41c-4.47,1.19-10.64,2.89-17.5,4.62s-13.13,3.07-17.71,3.82c-2.29.38-4.15.65-5.44.78l-1.49.15a1.86,1.86,0,0,1-.52,0,2,2,0,0,1,.51-.12l1.47-.25c1.28-.19,3.13-.52,5.4-.94,4.55-.84,10.8-2.23,17.65-4s13-3.38,17.54-4.48c2.25-.55,4.08-1,5.35-1.25l1.46-.3A2,2,0,0,1,417.3,297.6Z"
            fill={"#212121"}
          ></path>
        </g>
      </svg>
    </>
  );
}

export default FeelingProud;
